import React from "react";

const AboutContent = () => {
  return (
    <section className="bg_dark">
      <div className="sb_hd conta_iner text_white text_center exp_fns_box">
        <h2 data-aos="fade-up" data-aos-duration="1000">
          EXPERIENCE THE FINEST CAR <br />
          SERVICES IN THE WORLD
        </h2>
        <p data-aos="fade-up" data-aos-duration="2000">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English.
        </p>
        <button
          type="button"
          className="btnn btn_white big_cursor"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          Learn more
        </button>
      </div>
    </section>
  );
};

export default AboutContent;
