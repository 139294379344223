import React from "react";

const Callus = (props: any) => {
console.log(props,"props");

  return (
    <section className="call_sec">
      <div className="call_box sb_hd text_center text_white">
        <h2>CALL US</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </p>
        <h3>FOR GENERAL ASSISTANCE</h3>
        <h4>Phone Numbers:</h4>
        {/* <h5>Office Number: <a href={"tel:" + `${props?.data?.contactSupport?.dialCode + "" + props?.data?.contactSupport?.phoneNo }`} style={{
          fontSize: "18px",
          textDecoration: "underline",
          color:"white"
        }}>{props?.data?.contactSupport?.dialCode + "" + props?.data?.contactSupport?.phoneNo || "No contact provided"}
        </a></h5> */}

        <h5>
          Office Number:
          {props?.data?.contactSupport?.dialCode && props?.data?.contactSupport?.phoneNo ? (
            <a
              href={`tel:${props.data.contactSupport.dialCode}${props.data.contactSupport.phoneNo}`}
              style={{
                fontSize: "18px",
                textDecoration: "underline",
                color: "white"
              }}
            >
              {`${props.data.contactSupport.dialCode} ${props.data.contactSupport.phoneNo}`}
            </a>
          ) : (
            "No contact provided"
          )}
        </h5>




        {/* <h5>Manager Number: {props?.data?.contactSupport?.dialCode + "" + props?.data?.contactSupport?.phoneNo || "No contact provided"}</h5>
        <h5>For Inquiry: {props?.data?.contactSupport?.dialCode + "" + props?.data?.contactSupport?.phoneNo || "No contact provided"}</h5> */}
      </div>
      <p className="other_st">
        For the hearing and speech impaired, please call our Telecommunication
        Relay service at 711 for assistance.
      </p>
    </section>
  );
};

export default Callus;
