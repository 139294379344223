import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Services = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <>
      <section className="services-sec bg_dark">
        <div className="conta_iner">
          <div className="sb_hd text_center">
            <h2>Explore Services</h2>
            <p>Lorem Ipsum is simply dummy text of the printing </p>
          </div>
          <div className="srvc_flx">
            <div
              className="srvc_box big_cursor"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <figure>
                <img src="./assets/images/1.jpg" alt="service"></img>
              </figure>
              <div className="srvc_hdng">
                <h3>Car Rental </h3>
                <p>It is a long established fact that a reader </p>
              </div>
            </div>
            <div
              className="srvc_box big_cursor"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <figure>
                <img src="./assets/images/2.jpg" alt="service"></img>
              </figure>
              <div className="srvc_hdng">
                <h3>Car Rental </h3>
                <p>It is a long established fact that a reader </p>
              </div>
            </div>
            <div
              className="srvc_box big_cursor"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <figure>
                <img src="./assets/images/3.jpg" alt="service"></img>
              </figure>
              <div className="srvc_hdng">
                <h3>Car Rental </h3>
                <p>It is a long established fact that a reader </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
