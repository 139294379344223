import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import {
  Box,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Cross from "../../../../layout/component/cross-icon";
import { usePostSendOtpMutation, usePostVerifyOtpMutation, useVendorPostSendOtpMutation, useVendorPostVerifyOtpMutation } from "../../../../services/auth";
import { useDispatch } from "react-redux";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";
import { removeFromStorage, setToStorage } from "../../../../constants/storage";
import { STORAGE_KEYS } from "../../../../constants/storageKeys";
import { showError, showToast, showWarning } from "../../../../constants/toast";
import { setCredentials } from "../../../../reducers/authSlice";
import { isNumber } from "../../../../utils/validations";

const OTPForm = () => {
  const [otp, setOtp] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpApi] = usePostVerifyOtpMutation();
  const [sendOtpApi] = usePostSendOtpMutation();
  const [otpApiVendor] = useVendorPostVerifyOtpMutation();
  const [sendOtpApiVendor] = useVendorPostSendOtpMutation();
  const [countDown, setCountDown] = useState<number>(59);
  const state = location;
  console.log(state?.state, "state in otp");

  const handleSubmit = async () => {
    removeFromStorage(
      STORAGE_KEYS.token
    );
    dispatch(
      setCredentials({
        user: null,
        token: null,
      })
    );
    const body = {
      otp: otp,
      verificationType: 1,
      email: state?.state?.email
    };

    console.log(body, "body for verify otp");
    if (state?.state?.person === "vendor") {
      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const res = await otpApiVendor(encryptedBody).unwrap();
        if (res?.statusCode === 200) {
          showToast("Otp verified succesfully")
          setToStorage(
            STORAGE_KEYS.token, (res?.data?.accessToken)
          );
          dispatch(
            setCredentials({
              user: res?.data || null,
              token: res?.data?.accessToken || null,
            })
          );
          navigate("/profile-setup", { state: { email: state?.state?.email, person: state?.state?.person },replace:true })
        }
      } catch (res: any) {
        showError(res?.data?.message);
      }
    } else {
      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const res = await otpApi(encryptedBody).unwrap();
        if (res?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token, (res?.data?.accessToken)
          );
          showToast("Otp verified succesfully")
          dispatch(
            setCredentials({
              user: res?.data || null,
              token: res?.data?.accessToken || null,
            })
          );
          navigate("/profile-setup", { state: { email: state?.state?.email, person: state?.state?.person }, replace: true })
        }
      } catch (res: any) {
        showError(res?.data?.message);
      }
    }
  };

  // Resend OTP
  const handleResendOtp = async () => {
    removeFromStorage(
      STORAGE_KEYS.token
    );
    dispatch(
      setCredentials({
        user: null,
        token: null,
      })
    );
    let data = {
      email: state?.state?.email,
      verificationType: 3,
    };
    console.log(data, "body in resent otp");

    if (state?.state?.person === "vendor") {
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await sendOtpApiVendor(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("OTP resent successfully");
          setCountDown(59);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    } else {
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await sendOtpApi(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message);
          setCountDown(59);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    }

  };

  const formatTime = (time: any) => {
    return time < 10 ? `0${time}` : time;
  };


  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  useEffect(() => {
    removeFromStorage(
      STORAGE_KEYS.token
    );
    dispatch(
      setCredentials({
        user: null,
        token: null,
      })
    );
  }, [])



  return (
    <>
      <div className="login_rhs w_50 otp_rhs">
        <Box>
          <div className="login-box otp_vw">
            <div className="login-heading" style={{ marginTop: "150px" }}>
              <h2 className="heading-font">Verification</h2>
              <p>We have just sent you a verification code on {state?.state?.email ? state?.state?.email : "your Email id"}</p>
            </div>
            <form >
              <div className="otp-input-container">
                {/* <OTPInput
                  value={otp}
                  shouldAutoFocus
                  onChange={setOtp}
                  numInputs={4}
                  inputStyle={{
                    width: '60px',
                    height: '60px',
                    fontSize: '28px',
                    border: '1px solid #a8a9ac',
                    borderRadius: '6px',
                    color: '#000',
                    marginRight: '10px',
                  }}
                  renderSeparator={<span></span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      inputMode="numeric"
                      pattern="\d*"
                      onKeyDown={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                /> */}
                <div className="opt_fields">
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  shouldAutoFocus
                  numInputs={4}
                  renderInput={(props) => <input {...props} />}
                  inputType="tel"
                />
</div>
                {countDown === 0 ? (
                  <h3>Didn't you recieve any code? <span style={{ cursor: "pointer", textDecoration: "underline", fontWeight: 600 }} onClick={() => { handleResendOtp(); setOtp("") }}>resend otp</span></h3>
                ) : (
                  <h3>The verification code will expire in{" "}
                    <div style={{ display: "flex", textAlign: "center", justifyContent: "center", gap: "5px" }}>
                      <p style={{ margin: 0, color: "#1d2b28", width: "35px" }}>
                        00
                      </p>
                      :
                      <p style={{ margin: 0, color: "#1d2b28", width: "35px" }}>
                        {formatTime(countDown)}
                      </p>
                    </div>
                  </h3>
                )}
              </div>
              <div className="form_btn text_center">
                <Button className="btnn btn_primary big_cursor" onClick={() => otp ? handleSubmit() : showWarning("Otp should not be empty")} >Submit</Button>
              </div>
            </form>

          </div>
          <Cross />
        </Box>
      </div>
    </>
  );
};

export default OTPForm;
