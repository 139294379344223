import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyGetCmsQuery } from "../../../services/cms";
import { showError } from "../../../constants/toast";
import Loader from "../../../constants/Loader";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const AboutInfo = () => {
    const [value, setValue] = useState<number>(0);
    const [getCmsData] = useLazyGetCmsQuery();
    const [cmsData, setCmsData] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchDataUser = async (value: number) => {
        setIsLoading(true);
        let role: number = 3;
        if (value === 0) {
            role = 3
        } else if (value === 1) {
            role = 4
        } else if (value === 2) {
            role = 5
        } else {
            role = 8
        }
        try {
            const res = await getCmsData({ role }).unwrap();
            if (res?.statusCode === 200) {
                setCmsData(res?.data);
            }
            setIsLoading(false)
        } catch (error: any) {
            showError(error?.data?.message || "Something went wrong")
            setIsLoading(false);
        }
    }

    console.log(cmsData, "cmsData");


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchDataUser(value);
    }, [value])

    return (
        <>
            <div className="dark_bg privacy-page text_center">
                <div className="conta_iner">
                    <Loader isLoad={isLoading} />
                    <Box className="custom_tabs">
                        <Box className="flx_sc">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="basic tabs example"
                                className="custom_tabs_links"
                            >
                                <Tab label="User" {...a11yProps(0)} />
                                <Tab label="Vendor" {...a11yProps(1)} />
                                <Tab label="Corporate" {...a11yProps(2)} />
                                <Tab label="Chauffer" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            {cmsData?.aboutUs ? (
                                <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsData?.aboutUs }} />
                            ) : (
                                <img className="nodata" src="./assets/images/noDataFound.png" alt="No data found" />
                            )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            {cmsData?.aboutUs ? (
                                <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsData?.aboutUs }} />
                            ) : (
                                <img className="nodata" src="./assets/images/noDataFound.png" alt="No data found" />
                            )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            {cmsData?.aboutUs ? (
                                <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsData?.aboutUs }} />
                            ) : (
                                <img className="nodata" src="./assets/images/noDataFound.png" alt="No data found" />
                            )}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            {cmsData?.aboutUs ? (
                                <p data-aos="fade-up" dangerouslySetInnerHTML={{ __html: cmsData?.aboutUs }} />
                            ) : (
                                <img className="nodata" src="./assets/images/noDataFound.png" alt="No data found" />
                            )}
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>
        </>
    );
};

export default AboutInfo;
