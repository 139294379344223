import React from 'react'
import Layout from "../../layout/index"
const FileSummarizationPage = () => {
  return (
    <Layout>
<div style={{display:"flex"}}>
    
        <div>FileSummarizationPage</div>
        {/* <Layout.SidePanel/> */}
        
</div>
</Layout>
  )
}

export default FileSummarizationPage