import React from "react";
import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import Reserve from "./features/reserve";
import Services from "./features/services";
import Contact from "../../layout/component/contact";

const Home = () => {
  const bannerData = {
    videoSrc: "./assets/images/hero.mp4",
    heading: "Enjoy Premium Luxury ",
    subheading: "Car Rental",
    description: "Enjoy Premium Luxury",
    showButton: true,
  };
  return (
    <Layout>
      <Banner
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
      />
      <Reserve />
      <Services />
      <Contact />
    </Layout>
  );
};

export default Home;
