import { Box, Button, createTheme, TextField, ThemeProvider } from "@mui/material";
import DocumentSuccessfull from "../../../../modals/document-successfull";
import React, { useEffect, useState } from "react";
import Cross from "../../../../layout/component/cross-icon";
import { UploadMedia } from "../../../../utils/mediaUpload";
import { makeStyles } from '@mui/styles';
import { showError, showToast } from "../../../../constants/toast";
import { useAddDocumentsForsignupMutation, useLazyGetReqDocumentsQuery } from "../../../../services/auth";
import { CommonBody, CorporateDocument, UploadDocument } from "../../../../types/General";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import Loader from "../../../../constants/Loader";
import { useLocation } from "react-router-dom";

interface FormErrors {
  issueDates: { [key: number]: string };
  expiryDates: { [key: number]: string };
  frontImages: { [key: number]: string };
  backImages: { [key: number]: string };
}



const Document = () => {
  const [open1, setOpen1] = React.useState(false);
  const handleClose1 = () => setOpen1(false);
  const handleOpen1 = () => setOpen1(true);
  const location = useLocation();
  const { state } = location;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getReqDocs] = useLazyGetReqDocumentsQuery();
  const [reqDoc, setReqDoc] = useState<CorporateDocument[]>([]);
  const [uploadDocsApi] = useAddDocumentsForsignupMutation();
  const today = new Date().toISOString().split("T")[0];
  console.log(state, "state in document");

  const [uploadDocArray, setUploadDocArray] = useState<UploadDocument[]>([{
    name: "",
    requiredDocumentId: "",
    frontImage: "",
    backImage: "",
    expiryDate: "",
    issueDate: "",
    docType: 1,
  },]);

  const [errors, setErrors] = useState<FormErrors>({
    issueDates: {},
    expiryDates: {},
    frontImages: {},
    backImages: {},
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const fetchReqDoc = async () => {
    setIsLoading(true)
    try {
      const res = await getReqDocs({ docType: 1 }).unwrap();
      if (res?.statusCode === 200) {
        setReqDoc(res?.data?.requiredDocuments);
      }
      setIsLoading(false)
    } catch (error: any) {
      showError(error?.data?.message)
      setIsLoading(false)
    }
  }

  const uploadDocuments = async () => {
    validateForm();
    if (!isFormValid) return;

    setIsLoading(true)
    const body = {
      documents: uploadDocArray
    }
    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await uploadDocsApi(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        handleOpen1();
      }
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      showError(error?.data?.message)
    }
  }

  const validateForm = () => {
    const newErrors: FormErrors = {
      issueDates: [],
      expiryDates: [],
      frontImages: [],
      backImages: [],
    };

    let valid = true;

    uploadDocArray.forEach((doc, index) => {
      if (reqDoc[index]?.issueDate && !doc.issueDate) {
        newErrors.issueDates[index] = "Issue Date is required";
        valid = false;
      }
      if (reqDoc[index]?.expiryDate && !doc.expiryDate) {
        newErrors.expiryDates[index] = "Expiry Date is required";
        valid = false;
      }
      if (reqDoc[index]?.frontImage && !doc.frontImage) {
        newErrors.frontImages[index] = "Front Image is required";
        valid = false;
      }
      if (reqDoc[index]?.backImage && !doc.backImage) {
        newErrors.backImages[index] = "Back Image is required";
        valid = false;
      }
    });

    setErrors(newErrors);
    setIsFormValid(valid);
  };


  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>, side: string, index: number
  ) => {
    setIsLoading(true)
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (file) {
      if (file.type.startsWith("image/")) {
        console.log(file, "FILE");
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setIsLoading(false)
          // showToast("Document uploaded successfully");
          if (side === "front") {
            const updatedArray = [...uploadDocArray];
            updatedArray[index] = {
              ...updatedArray[index],
              frontImage: res?.data?.image,
            };
            setUploadDocArray(updatedArray);
          } else if (side === "back") {
            const updatedArray = [...uploadDocArray];
            updatedArray[index] = {
              ...updatedArray[index],
              backImage: res?.data?.image,
            };
            setUploadDocArray(updatedArray);
          }
        } else {
          setIsLoading(false)
          showError(res?.message);
        }
      } else {
        setIsLoading(false)
        showError("Failed to upload document");
      }
    }
    setIsLoading(false)
  };

  console.log(reqDoc, "reqDOcs");



  const handleIssueDateChange = (e: any, index: number) => {
    const updatedArray = [...uploadDocArray];
    updatedArray[index] = {
      ...updatedArray[index],
      issueDate: e.target.value,
    };
    setUploadDocArray(updatedArray);
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDate = tomorrow.toISOString().split("T")[0];

  const handleExpiryDateChange = (e: any, index: number) => {
    const updatedArray = [...uploadDocArray];
    updatedArray[index] = {
      ...updatedArray[index],
      expiryDate: e.target.value,
    };
    setUploadDocArray(updatedArray);
  };

  useEffect(() => {
    fetchReqDoc();
  }, [])

  useEffect(() => {
    validateForm();
  }, [uploadDocArray]);

  useEffect(() => {
    if (reqDoc?.length) {
      const updatedArray = reqDoc.map((item, index) => ({
        ...uploadDocArray[index],
        requiredDocumentId: item?._id,
        name: item?.name,
        docType: 1,
        issueDate: "",
        expiryDate: "",
        frontImage: "",
        backImage: "",
      }));
      setUploadDocArray(updatedArray);
    }
  }, [reqDoc]);

  console.log(uploadDocArray, "uploadDocArray---->>");


  return (
    <>
      <div className="login_rhs w_50">
        <Loader isLoad={isLoading} />
        <div className="login-box">
          <div className="login-heading">
            <h2 className="heading-font">Documents/Proof</h2>
          </div>

          <form className="form">
            {reqDoc?.length ? reqDoc.map((item, index: number) => {
              return (
                <Box style={{ border: "solid lightGray", borderRadius: "10px", padding: "10px", marginBottom: "10px" }}>
                  <div className="control_group">
                    <TextField
                      hiddenLabel
                      placeholder="Document Name"
                      fullWidth
                      value={item?.name}
                      disabled
                      style={{
                        fontStyle: "italic",
                        fontWeight: "700",
                        fontSize: "larger"
                      }}
                    />
                  </div>
                  <div className="date_flx">
                    {item?.issueDate ? (
                      <div className="control_group">
                        <label className="custom_label">Issue Date</label>
                        <TextField
                          hiddenLabel
                          type="date"
                          name="startTime"
                          variant="outlined"
                          fullWidth
                          placeholder="Start Time"
                          onFocus={(e) => {
                            (e.target as HTMLInputElement).click();
                          }}
                          onChange={(e) => handleIssueDateChange(e, index)}
                          inputProps={{ max: today }}

                        />
                        {errors.issueDates[index] && <div className="error-message">{errors.issueDates[index]}</div>}
                      </div>
                    ) : (
                      null)}
                    {item?.expiryDate ? (
                      <div className="control_group">
                        <label className="custom_label"> Expiry Date</label>
                        <TextField
                          hiddenLabel
                          type="date"
                          name="endTime"
                          variant="outlined"
                          fullWidth
                          placeholder="End Time"
                          onChange={(e) => handleExpiryDateChange(e, index)}
                          inputProps={{ min: tomorrowDate }}
                          onFocus={(e) => {
                            (e.target as HTMLInputElement).click();
                          }}
                        />
                        {errors.expiryDates[index] && <div className="error-message">{errors.expiryDates[index]}</div>}
                      </div>
                    ) : (
                      null)}
                  </div>

                  <ul className="proof-flx">
                    {item?.frontImage ? (
                      <div className="upload_proof">
                        <figure>
                          <img
                            src={uploadDocArray[index]?.frontImage ? uploadDocArray[index]?.frontImage : "./assets/images/doc.svg"}
                            alt="doc"
                          ></img>
                        </figure>
                        <figcaption>Upload Front Side</figcaption>
                        <input
                          placeholder="img"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => handleImageUpload(e, "front", index)}
                          type="file"
                        ></input>
                        {errors.frontImages[index] && <div className="error-message">{errors.frontImages[index]}</div>}
                      </div>
                    ) : (
                      null)}
                    {item?.backImage ? (
                      <div className="upload_proof">
                        <figure>
                          <img
                            src={uploadDocArray[index]?.backImage ? uploadDocArray[index]?.backImage : "./assets/images/doc.svg"}
                            alt="doc"
                          ></img>
                        </figure>
                        <figcaption>Upload Back Side</figcaption>
                        <input
                          placeholder="img"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => handleImageUpload(e, "back", index)}
                          type="file"
                        ></input>
                        {errors.backImages[index] && <div className="error-message">{errors.backImages[index]}</div>}
                      </div>

                    ) : (
                      null)}
                  </ul>
                </Box>
              )
            }) : ("")}

            <div className="form_btn text_center">
              <Button
                className="btnn btn_primary big_cursor"
                onClick={uploadDocuments}
                disabled={!isFormValid}
              >
                Submit
              </Button>
            </div>

          </form>
          <Cross />
        </div>
      </div>
      <DocumentSuccessfull open={open1} onClose={handleClose1} />
    </>
  );
};

export default Document;
