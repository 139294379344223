import { Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Cross from "../../../../layout/component/cross-icon";
import { useEffect, useState } from "react";
import { useVendorPostProfileSetupMutation } from "../../../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";
import { showError, showToast } from "../../../../constants/toast";
import { setCredentials } from "../../../../reducers/authSlice";
import { getFromStorage } from "../../../../constants/storage";
import { STORAGE_KEYS } from "../../../../constants/storageKeys";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import { UploadMedia } from "../../../../utils/mediaUpload";
import { isNumber, isString } from "../../../../utils/validations";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Autocomplete } from "@react-google-maps/api";
import Loader from "../../../../constants/Loader";
import { GOOGLE_API_KEY } from "../../../../constants/url";
import CloseIcon from '@mui/icons-material/Close';

type Bank = {
    bankName: string;
    accountHolderName: string;
    branchName: string;
    branchCode: string;
    accountNo: string;
}


declare global {
    interface Window {
        google: typeof google;
    }
}


type Body = {
    email: string;
    firstName: string;
    storeName: string;
    phoneNo: string;
    dialCode: string;
    address: string;
    image?: string;
    city: string;
    state: string;
    country: string;
    longitude: number;
    latitude: number;
    pinCode: string;
    storeOpeningTime: string;
    storeClosingTime: string;
    bankDetail: Bank;
    countryName: string;
}

const VendorProfileSetup = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [updateProfile] = useVendorPostProfileSetupMutation();
    const dispatch = useDispatch();
    const [load, setLoad] = useState<boolean>(false);
    const token = getFromStorage(STORAGE_KEYS?.token);
    console.log(token, "token for profile");
    const location = useLocation();
    const { state } = location;
    const [autocomplete, setAutocomplete] = useState(null)
    const [latlng, setLatlong] = useState({
        lat: 0,
        long: 0
    })
    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target;
        const file = files?.files?.length ? files?.files[0] : "";
        if (file) {
            if (file.type.startsWith("image/")) {
                console.log(file, "FILE");
                setLoad((prev) => !prev);
                formik.setFieldValue("image", URL.createObjectURL(file));
                const res = await UploadMedia(file);
                if (res?.statusCode === 200) {
                    formik.setFieldValue("image", res?.data?.image);
                    showToast("Image uploaded successfully")
                    setLoad((prev) => !prev);
                }
                else {
                    showError(res?.message);
                    setLoad((prev) => !prev);
                }
            } else {
                showError("Failed to upload image");
                setLoad((prev) => !prev);
            }
        }
    };
    console.log(state, "state in profile");


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            firstName: "",
            storeName: "",
            phoneNo: "",
            dialCode: "",
            address: "",
            image: "",
            city: "",
            state: "",
            country: "",
            pinCode: "",
            storeOpeningTime: "",
            storeClosingTime: "",
            bankName: "",
            accountHolderName: "",
            branchName: "",
            branchCode: "",
            accountNo: "",
            longitude: "",
            latitude: "",
            countryName: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required("Name is required")
                .min(3, "Minimum 3 characters are required")
                .max(80, "Maximum 80 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "Name must contain only alphabets"
                ),
            storeName: Yup.string()
                .required("Store name is required")
                .min(3, "Minimum 3 characters are required")
                .max(80, "Maximum 80 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "Store name must contain only alphabets"
                ),
            phoneNo: Yup.string()
                .required("Phone number is required")
                .min(5, "Minimum 5 digits are required")
                .max(15, "Maximum 15 digits are required"),
            dialCode: Yup.string().required("Country code is required"),
            address: Yup.string()
                .required("Address is required")
                .min(3, "Minimum 3 characters are required"),
            // .max(50, "Maximum 50 characters is required"),
            city: Yup.string()
                // .required("City is required")
                .min(3, "Minimum 3 characters are required")
                .max(40, "Maximum 40 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "City must contain only alphabets"
                ),
            state: Yup.string()
                .required("State is required")
                .min(3, "Minimum 3 characters are required")
                .max(40, "Maximum 40 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "State must contain only alphabets"
                ),
            country: Yup.string()
                .required("Country is required")
                .min(3, "Minimum 3 characters are required")
                .max(40, "Maximum 40 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "Country must contain only alphabets"
                ),
            pinCode: Yup.string().required("Pincode is required")
                .max(10, "Maximum 10 characters is required"),
            storeOpeningTime: Yup.string().required("Store opening time is required"),
            storeClosingTime: Yup.string()
                .required('Store closing time is required')
                .test('is-greater', 'Closing time must be after opening time', function (value) {
                    const { storeOpeningTime } = this.parent;
                    if (!storeOpeningTime || !value) return true; // If either is not provided, validation passes
                    return value > storeOpeningTime; // Simple string comparison
                }),
            bankName: Yup.string().required("Bank name is required")
                .min(3, "Minimum 3 characters are required")
                .max(40, "Maximum 40 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "Bank name must contain only alphabets"
                ),
            accountHolderName: Yup.string().required("Account holder name is required")
                .min(3, "Minimum 3 characters are required")
                .max(40, "Maximum 40 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "Account holder name must contain only alphabets"
                ),
            branchName: Yup.string().required("Branch name is required")
                .min(3, "Minimum 3 characters are required")
                .max(40, "Maximum 40 characters is required")
                .matches(
                    /^[A-Za-z\s'-]+$/,
                    "Branch name must contain only alphabets"
                ),
            branchCode: Yup.string().required("Branch code is required")
                .min(3, "Minimum 3 characters are required")
                .max(20, "Maximum 20 characters is required"),
            accountNo: Yup.string().required("Account number is required")
                .min(3, "Minimum 3 characters are required")
                .max(20, "Maximum 20 characters is required"),
        }),

        onSubmit: async (values) => {
            formik.setSubmitting(true);


            let body: Body = {
                email: state?.email,
                firstName: values.firstName,
                storeName: values.storeName,
                phoneNo: values.phoneNo,
                dialCode: values.dialCode,
                address: values.address,
                countryName: values.countryName,
                country: values.country,
                pinCode: values.pinCode,
                city: values.city,
                state: values.state,
                longitude: latlng?.long,
                latitude: latlng?.lat,
                storeOpeningTime: values.storeOpeningTime,
                storeClosingTime: values.storeClosingTime,
                bankDetail: {
                    bankName: values.bankName,
                    accountHolderName: values.accountHolderName,
                    branchName: values.branchName,
                    branchCode: values.branchCode,
                    accountNo: values.accountNo,
                }
            };
            if (values.image) {
                body.image = values.image;
            }
            console.log("body for setup vendor", body);

            try {
                setIsLoading(true)
                let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
                const response = await updateProfile(encryptedBody).unwrap();
                if (response?.statusCode === 200) {
                    showToast("Profile setup completed successfully")
                    navigate("/document-upload", { state: "vendor", replace: true })
                    dispatch(
                        setCredentials({
                            user: response?.data || null,
                            token: token || null,
                        }),
                    );
                }
                setIsLoading(false)
            } catch (errors: any) {
                console.log(errors, "errrr");
                setIsLoading(false)
                showError(errors?.data?.message);
            }
        },
    });

    console.log("formik image", formik.values.image);


    const onLoadG = (autocompleteObj: any) => {
        setAutocomplete(autocompleteObj);

    };


    const onPlaceChanged = async () => {
        if (autocomplete) {
            let place = await (autocomplete as any).getPlace()
            if (place && place.address_components) {
                let address = place.address_components;
                let state,
                    city,
                    country,
                    zip = "";
                address.forEach(function (component: any) {
                    let types = component.types;
                    if (types.indexOf("locality") > -1 || types.indexOf("administrative_area_level_3") > -1) {
                        city = component.long_name;
                    }
                    if (types.indexOf("postal_code") > -1) {
                        zip = component.long_name;
                    }
                    if (types.indexOf("administrative_area_level_1") > -1) {
                        state = component?.long_name || "";
                    }
                    if (types.indexOf("country") > -1) {
                        country = component?.long_name || "";
                    }
                });

                var lat = place.geometry.location.lat();
                var lng = place.geometry.location.lng();
                // setLat1(lat)
                // setLng1(lng)
                setLatlong({
                    lat: lat,
                    long: lng
                })


                formik.setFieldValue("address", `${place?.formatted_address}`);
                formik.setFieldValue("city", city || "");
                formik.setFieldValue("country", country || "");
                formik.setFieldValue("state", state || "");
                formik.setFieldValue("pinCode", zip || "");
                formik.setFieldValue("latitude", lat || "");
                formik.setFieldValue("longitude", lng || "");
            }
        }
    };

    const handleClear = () => {
        setLatlong({
            lat: 0,
            long: 0
        })
        formik.setFieldValue("address", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("country", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("pinCode", "");
    };

    const handleTimeChange = (event: any, field: string) => {
        const time = event.target.value;
        const [hours, minutes] = time.split(':');
        const date = new Date();
        date.setUTCHours(hours, minutes, 0, 0);
        const utcTime = date.toISOString();
        formik.setFieldValue(field, utcTime);
    };


    const handleChangePhone = (phone: any, country: any) => {
        formik.setFieldValue("phoneNo", phone?.replace(country.dialCode, ""));
        formik.setFieldValue(
            "dialCode",
            country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
        );
        formik.setFieldValue("countryName", country?.name.toUpperCase());
    };

    useEffect(() => {
        let googleMapsScript: HTMLScriptElement;

        const loadGoogleMaps = async () => {
            googleMapsScript = document.createElement("script");
            googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=drawing,places`;
            googleMapsScript.async = true;
            await new Promise((resolve, reject) => {
                googleMapsScript.onload = resolve;
                googleMapsScript.onerror = reject;
                document.body.appendChild(googleMapsScript);
            });
        };

        loadGoogleMaps();

        return () => {
            if (googleMapsScript && googleMapsScript.parentNode === document.body) {
                document.body.removeChild(googleMapsScript);
            }
        };
    }, []);

    return (
        <>
            <div className="login_rhs w_50">
                <Loader isLoad={isLoading} />
                <div className="login-box">
                    <div className="login-heading">
                        <h2 className="heading-font">Profile Setup</h2>
                        <p>Login to Continue</p>
                    </div>
                    <form className="form" onSubmit={formik.handleSubmit}>
                        {formik.values.image ? (
                            <div className="upload-profile">
                                <figure className={`${load ? "loading" : ""}`}>
                                    <img className={formik.values.image ? "cover_img" : ""} src={formik.values.image} alt="user"></img>
                                </figure>
                                <div className="action">
                                    <CancelIcon
                                        onClick={() => {
                                            formik.setFieldValue("image", "");
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="upload-profile">
                                <figure >
                                    <img src="./assets/images/user.svg" alt="icon" />
                                    <input
                                        placeholder="img"
                                        type="file"
                                        title="image"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={handleImageUpload}
                                    />
                                </figure>
                                <div className="action">
                                    <AddIcon />
                                    <input
                                        placeholder="img"
                                        type="file"
                                        title="image"
                                        accept=".png, .jpg, .jpeg"
                                        onChange={handleImageUpload}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="control_group">
                            <label>Owner name</label>
                            <TextField
                                hiddenLabel
                                placeholder="Enter Name"
                                fullWidth
                                className="text_field"
                                inputProps={{ maxLength: 80 }}
                                name="firstName"
                                onChange={(val) => {
                                    if (
                                        val.target.value === " " ||
                                        val.target.value === "."
                                    ) {
                                    } else if (isString(val.target.value)) {
                                        formik.handleChange(val);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                                helperText={
                                    formik.touched.firstName && formik.errors.firstName
                                }
                            />
                        </div>
                        <div className="control_group">
                            <label>Store name</label>
                            <TextField
                                hiddenLabel
                                placeholder="Enter store Name"
                                fullWidth
                                className="text_field"
                                inputProps={{ maxLength: 80 }}
                                name="storeName"
                                onChange={(val) => {
                                    if (
                                        val.target.value === " " ||
                                        val.target.value === "."
                                    ) {
                                    } else if (isString(val.target.value)) {
                                        formik.handleChange(val);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.storeName}
                                helperText={
                                    formik.touched.storeName && formik.errors.storeName
                                } />
                        </div>
                        <div className="control_group">
                            <label>Email</label>
                            <TextField
                                hiddenLabel
                                placeholder="Enter your email"
                                fullWidth
                                className="text_field"
                                disabled
                                name="email"
                                inputProps={{ maxLength: 80 }}
                                value={state?.email}
                                onChange={(val) => {
                                    if (
                                        val.target.value === " " ||
                                        val.target.value === "."
                                    ) {
                                    } else {
                                        formik.handleChange(val);
                                    }
                                }}
                                onBlur={formik.handleBlur}

                            />
                        </div>

                        <div className="control_group">
                            <label>Phone Number</label>
                            <PhoneInput
                                country={"us"}
                                placeholder="Phone number"
                                inputStyle={{ width: "100%" }}
                                onChange={(phone, country) =>
                                    handleChangePhone(phone, country)
                                }
                                onBlur={formik.handleBlur}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault(); // Prevent default behavior (form submission)
                                        formik.handleSubmit(); // Manually submit the form
                                    }
                                }}
                                value={formik.values.dialCode + formik.values.phoneNo}
                            />
                            {formik.touched.phoneNo && formik.errors.phoneNo ? (
                                <h6 className="err_msg">
                                    {formik.touched.phoneNo && formik.errors.phoneNo}
                                </h6>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="control_group">
                            <label>Store opening tme</label>
                            <TextField
                                hiddenLabel
                                type="time"
                                name="storeOpeningTime"
                                variant="outlined"
                                fullWidth
                                placeholder="Start Time"
                                className="text_field"
                                inputProps={{
                                    maxLength: 30,
                                    min: "00:00"
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.storeOpeningTime ? new Date(formik.values.storeOpeningTime).toISOString().substring(11, 16) : ''}
                                onChange={(e) => handleTimeChange(e, "storeOpeningTime")}
                                helperText={formik.touched.storeOpeningTime && formik.errors.storeOpeningTime}
                            />
                        </div>
                        <div className="control_group">
                            <label>Store closing time</label>
                            <TextField
                                hiddenLabel
                                type="time"
                                name="storeClosingTime"
                                variant="outlined"
                                fullWidth
                                placeholder="End Time"
                                className="text_field"
                                // inputProps={{ maxLength: 30 }}
                                inputProps={{
                                    maxLength: 30,
                                    min: formik.values.storeOpeningTime || "00:00"
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.storeClosingTime ? new Date(formik.values.storeClosingTime).toISOString().substring(11, 16) : ''}
                                onChange={(e) => handleTimeChange(e, "storeClosingTime")}
                                helperText={formik.touched.storeClosingTime && formik.errors.storeClosingTime}
                            />
                        </div>
                        <div className="control_group">
                            <label>Address</label>
                            <Autocomplete
                                onLoad={onLoadG}
                                onPlaceChanged={() => onPlaceChanged()}
                            >
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter address"
                                    fullWidth
                                    className="text_field"
                                    name="address"
                                    onChange={formik.handleChange}
                                    inputProps={{ maxLength: 150 }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.address}
                                    helperText={formik.touched.address && formik.errors.address}
                                    InputProps={{
                                        endAdornment: formik.values.address ? (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClear}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                />
                            </Autocomplete>
                        </div>
                        <div className="control_group">
                            <label>City</label>
                            <TextField
                                hiddenLabel
                                placeholder="Enter your city"
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                name="city"
                                className="text_field"
                                onChange={(e) => {
                                    if (isString(e.target.value)) {
                                        formik.handleChange(e);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                                helperText={formik.touched.city && formik.errors.city}
                            />
                        </div>
                        <div className="control_group">
                            <label>State</label>
                            <TextField
                                hiddenLabel
                                placeholder="Enter your state"
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                name="state"
                                className="text_field"
                                onChange={(e) => {
                                    if (isString(e.target.value)) {
                                        formik.handleChange(e);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                                helperText={formik.touched.state && formik.errors.state}
                            />
                        </div>
                        <div className="control_group">
                            <label>Country</label>
                            <TextField
                                hiddenLabel
                                placeholder="Enter your Country"
                                inputProps={{ maxLength: 40 }}
                                fullWidth
                                name="country"
                                className="text_field"
                                onChange={(e) => {
                                    if (isString(e.target.value)) {
                                        formik.handleChange(e);
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.country}
                                helperText={formik.touched.country && formik.errors.country}
                            />
                        </div>
                        <div className="control_group">
                            <label>Pin/Zipcode</label>
                            <TextField
                                hiddenLabel
                                placeholder="Enter your pin code"
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                name="pinCode"
                                className="text_field"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pinCode}
                                helperText={formik.touched.pinCode && formik.errors.pinCode}
                            />
                        </div>

                        <Box sx={{ border: "solid lightGray", borderRadius: "20px", padding: "10px" }}>
                            <h2>Bankng Details</h2>
                            <div className="control_group">
                                <label>Bank Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter your bank name"
                                    inputProps={{ maxLength: 40 }}
                                    fullWidth
                                    name="bankName"
                                    className="text_field"
                                    onChange={(e) => {
                                        if (isString(e.target.value)) {
                                            formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.bankName}
                                    helperText={formik.touched.bankName && formik.errors.bankName}
                                />
                            </div>
                            <div className="control_group">
                                <label>Account holder's Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter Name"
                                    inputProps={{ maxLength: 40 }}
                                    fullWidth
                                    name="accountHolderName"
                                    className="text_field"
                                    onChange={(e) => {
                                        if (isString(e.target.value)) {
                                            formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.accountHolderName}
                                    helperText={formik.touched.accountHolderName && formik.errors.accountHolderName}
                                />
                            </div>
                            <div className="control_group">
                                <label>Account no.</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter your account number"
                                    inputProps={{ maxLength: 20 }}
                                    fullWidth
                                    name="accountNo"
                                    className="text_field"
                                    onChange={(e) => {
                                        if (isNumber(e.target.value)) {
                                            formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.accountNo}
                                    helperText={formik.touched.accountNo && formik.errors.accountNo}
                                />
                            </div>
                            <div className="control_group">
                                <label>Branch Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter your branch name"
                                    inputProps={{ maxLength: 40 }}
                                    fullWidth
                                    name="branchName"
                                    className="text_field"
                                    onChange={(e) => {
                                        if (isString(e.target.value)) {
                                            formik.handleChange(e);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.branchName}
                                    helperText={formik.touched.branchName && formik.errors.branchName}
                                />
                            </div>
                            <div className="control_group">
                                <label>Branch Code</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter your branch code"
                                    inputProps={{ maxLength: 40 }}
                                    fullWidth
                                    name="branchCode"
                                    className="text_field"
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); 
                                        formik.setFieldValue('branchCode', value);
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.branchCode}
                                    helperText={formik.touched.branchCode && formik.errors.branchCode}
                                />
                            </div>
                        </Box>

                        <div className="form_btn text_center">
                            <Button
                                className="btnn btn_primary big_cursor"
                                type="submit"
                            // onClick={() => navigate("/document-upload")}
                            >
                                Submit
                            </Button>
                        </div>
                    </form >

                    <Cross />
                </div >
            </div >
        </>
    );
};

export default VendorProfileSetup;
