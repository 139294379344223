import React from "react";
import { useNavigate } from "react-router-dom";

const Organisation = () => {
  const navigate = useNavigate();
  return (
    <section className="organisation-sec">
      <div className="conta_iner">
        <div className="sb_hd text-white" >
          <h2 data-aos="fade-up"
              data-aos-duration="1000">Join our organization</h2>
          <p data-aos="fade-up"
              data-aos-duration="2000">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet.
          </p>
          <button className="btnn btn_white big_cursor" type="button" data-aos="fade-up"
              data-aos-duration="3000">
            <span onClick={() => navigate('/login')}>Sign Up</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Organisation;
