import React from "react";

const ChooseEsqare = () => {
  return (
    <section className="bg_dark why_chs">
      <div className="conta_iner">
        <div className="sb_hd text_white text_center mb_50">
          <h2>Why Choose Esqyre</h2>
          <p>
            Lorem Ipsum is simply dummy text of the printing and
            <br /> typesetting industry.
          </p>
        </div>
        <div className="d_flx mt_40">
          <div className="pick-up" data-aos="fade-up" data-aos-duration="1000">
            <h3>Free Pick-Up & Drop-Off</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
          <div className="pick-up" data-aos="fade-up" data-aos-duration="2000">
            <h3>24/7 Roadside Assistance</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
          <div className="pick-up" data-aos="fade-up" data-aos-duration="3000">
            <h3>Easy Booking Process</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseEsqare;
