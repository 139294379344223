import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

type props = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};

const SidePanel = ({ show, setShow }: props) => {
  const navigate = useNavigate();
  return (
    <div>
      <ul className={show ? "show_sidebar sidebar-box" : "sidebar-box"}>
        <li className="close_sidebar" onClick={()=>setShow(false)}>
          <figure>
            <img src="./assets/images/cross.svg" alt="logo"></img>
          </figure>
        </li>
        <li onClick={() => navigate("/")} className="big_cursor">Home</li>
        <li onClick={() => navigate("/about")} className="big_cursor">About Us</li>
        <li onClick={() => navigate("/service")} className="big_cursor">Services</li>
        <li onClick={() => navigate("/contact-us")} className="big_cursor">Contact Us</li>
        <li onClick={() => navigate("/faq")} className="big_cursor">FAQ'S</li>
      </ul>
      <div className={show ? "show_sidebar otsd_clcl" : "otsd_clcl"}onClick={()=>setShow(false)}>
        <span className="close_sidebar" ></span>
      </div>
    </div>

  );
};

export default SidePanel;
