import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import Contact from "../../layout/component/contact";
import Carousel from "../../layout/component/carousel";
import AboutContent from "./features/about-content";
import ChooseEsqare from "./features/choose-esqaure";
import Organisation from "./features/organisation";
import AboutInfo from "./features/aboutInfo";



const About = () => {
  const bannerData = {
    videoSrc: "./assets/images/about.mp4",
    heading: "About us",
    subheading: "",
    description: "About us",
    showButton: false,
  };
  return (
    <Layout>
      <Banner
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
      />
      <AboutInfo />
      <AboutContent />
      <Carousel />
      <ChooseEsqare />
      <Organisation />
      <Contact />
    </Layout>
  );
};

export default About;
