import React from "react";

const Reserve = () => {
  return (
    <>
      <section className="reserve-sec">
        <div className="conta_iner">
          <div className="rs_hd">
            <h2 data-aos="fade-up" data-aos-duration="2000">
              Esqyre Reserve
            </h2>
            <button
              className="btnn btn_white big_cursor"
              type="button"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <span>Explore</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reserve;
