import React from "react";

type props = {
  id: number;
  image: string;
  heading: string;
  description: string;
};
const ServiceBanner = ({ id, image, heading, description }: props) => {
  return (
    <section
      className={id === 2 ? "carrentel-sec_ryt carrentel-sec" : "carrentel-sec"}
      style={{
        backgroundImage: `url('${image}')`,
      }}
    >
      <div className="conta_iner">
        <div className="sb_hd text-white">
          <h2 data-aos="fade-up" data-aos-duration="2000">
            {" "}
            {heading || ""}
          </h2>
          <p data-aos="fade-up" data-aos-duration="3000">
            {" "}
            {description || ""}
          </p>
        </div>
      </div>
    </section>
  );
};
export default ServiceBanner;
