import React, { useEffect } from "react";
import LoginForm from "./featured/login-form";
import LoginContent from "../../../layout/component/login-content";

const LoginPage = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, []);
  
  return (
    <>
      <section className="login-page">
          <div className="gap_m">
            <LoginContent />
            <LoginForm />
          </div>
      </section>
    </>
  );
};

export default LoginPage;
