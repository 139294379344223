import React from "react";
import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import AboutContent from "./features/experience";
import ServiceBanner from "../../layout/component/service-banner";
import Contact from "../../layout/component/contact";

const Service = () => {
  const bannerData = {
    videoSrc: "./assets/images/service.mp4",
    heading: "Services",
    subheading: "",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    showButton: false
  };
  // const ServiceData = {
  //   image: "./assets/images/service1.jpg",
  //   heading: "Car Rental",
  //   description: "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  // };
  // const ServiceData2 = {
  //   image: "./assets/images/service2.jpg",
  //   heading: "Chauffeur Rental",
  //   description: "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  // };
  // const ServiceData3 = {
  //   image: "./assets/images/service3.jpg",
  //   heading: "Corporate Rental",
  //   description: "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  // };
const serviceData=[
  {
    id:1,
    image: "./assets/images/service1.jpg",
    heading: "Car Rental",
        description: "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  },
  {
    id:2,
    image: "./assets/images/service2.jpg",
    heading:  "Chauffeur Rental",
        description: "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  },
  {
    id:3,
    image: "./assets/images/service3.jpg",
    heading: "Corporate Rental",
    description: "There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
  }
]


  return (
    <Layout>
      <Banner
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
      />
      <AboutContent />
{serviceData?.map((item)=>{
  return(
    <ServiceBanner
    key={item?.id}
    id={item?.id}
    image={item?.image}
    heading={item?.heading}
    description={item?.description}
  />
  )
})}

      {/* <ServiceBanner
        image={serviceData?.image}
        heading={ServiceData?.heading}
        description={ServiceData?.description}
      />
      <ServiceBanner
        image={ServiceData2?.image}
        heading={ServiceData2?.heading}
        description={ServiceData2?.description}
      />
      <ServiceBanner 
        image={ServiceData3?.image}
        heading={ServiceData3?.heading}
        description={ServiceData3?.description}
      /> */}
      <Contact />
    </Layout>
  );
};

export default Service;
