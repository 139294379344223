import React from "react";

const AboutContent = () => {
  return (
    <section className="about-sec bg_dark">
      <div className="conta_iner">
        <div className="d_flx">
          <div className="ab_lhs sb_hd text_white" data-aos="fade-right"
              data-aos-duration="3000">
            <h2>Esqyre Car Rental</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
            <button className="btnn btn_white big_cursor" type="button">
                <span>Book Now</span>
              </button>
          </div>
          <div className="ab_rhs text_center" data-aos="fade-left"
              data-aos-duration="3000">
            <figure>
              <img src="./assets/images/logo2.svg" alt="logo"></img>
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutContent;
