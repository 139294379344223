import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type props = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
};

function Header({ show, setShow }: props) {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, []);
  
  return (
    <>
      <header className={scroll ? "bg-black" : ""}>
        <div className="conta_iner">
          <div className="header_flx">
            <div className="tlg_bx big_cursor">
              <button className="menu-toogle" onClick={() => setShow(true)}>
                <figure>
                  <img src="./assets/images/menu.svg" alt="logo"></img>
                </figure>
              </button>
            </div>
            <figure className="logo-box big_cursor" onClick={() => navigate("/")}>
              <img src="./assets/images/logo.svg" alt="logo"></img>
            </figure>
            <ul className="nav_box">
              <li onClick={() => navigate("/login")} className="big_cursor">Sign In</li>
              <li className="lang-select">
                {" "}
                <figure className="logo-box big_cursor" onClick={() => navigate("/")}>
                  <img src="./assets/images/eng.png" alt="logo"></img>
                </figure>{" "}
                EN
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
