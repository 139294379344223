import crypto from "crypto-js";


export function generateSEKAndHash(token: string | null) {

  const iv = "9g5y5fdlctdegrtv".slice(0, 16);
  try {

    const timestamp = new Date().toISOString();
    const deviceId = navigator.userAgent;
    console.log(token,"token in data payload");
    
    const key = crypto.lib.WordArray.random(32);
    // const key = "8pa7aohegahg5j2";
    let dataPayload = {};
    if (token === "" || token === null || token === undefined) {
      dataPayload = {
        appKey: timestamp,
      };

    } else {
      dataPayload = {
        authorization: `Bearer ${token}`,
        appKey: new Date().toISOString(),
        deviceId: timestamp,

      };
    }
    console.log(dataPayload,"data payload");
    
    // let dataPayload = { appKey: timestamp };
    let appkey = JSON.stringify(dataPayload);
    console.log("ABCDEF",dataPayload);
    
    const encrypted = crypto.AES.encrypt(appkey, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
      // padding: crypto.pad.Pkcs7
    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
      appkey
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}

export function generateEncryptedKeyBody(body: any) {
  const iv = "9g5y5fdlctdegrtv";
  try {
    const key = crypto.lib.WordArray.random(32);
    let dataPayload = JSON.stringify(body);

    const encrypted = crypto.AES.encrypt(dataPayload, key, {
      iv: crypto.enc.Utf8.parse(iv),
      mode: crypto.mode.CBC,
      // padding: crypto.pad.Pkcs7

    });
    const encryptedHex = encrypted.ciphertext.toString();
    const keyHash = key.toString();

    return {
      hash: keyHash,
      sek: encryptedHex,
    };
  } catch (error) {
    console.error("", error);
    return null;
  }
}