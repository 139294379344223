import React, { useEffect, useLayoutEffect } from "react";
import LoginContent from "../../../layout/component/login-content";
import OTPForm from "./featured/otp";
import { removeFromStorage } from "../../../constants/storage";
import { STORAGE_KEYS } from "../../../constants/storageKeys";
import { resetAuth } from "../../../reducers/authSlice";

const OTPPage = () => {
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, []);
  useLayoutEffect(()=>{
    resetAuth()
  },[])
  return (
    <>
      <section className="login-page">
          <div className="gap_m">
            <LoginContent />
            <OTPForm />
          </div>
      </section>
    </>
  );
};

export default OTPPage;
