import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";


type CommonResponseType = {
    statusCode: number;
    mesaage: string;
};



export const cmsApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getCms: builder.query<
            CommonResponseType & { data: any },
            { role: any }>({
                query: ({role}) => ({
                    url: `${END_POINTS.getCms}?role=${role}`,
                    method: "GET",
                })
            }),
        getFaq: builder.query<
            CommonResponseType & { data: any },
            { role: any }>({
                query: ({role}) => ({
                    url: `${END_POINTS.faq}?role=${role}`,
                    method: "GET",
                })
            }),

    }),
});

export const {
    useLazyGetCmsQuery,
    useLazyGetFaqQuery,
} = cmsApi;

