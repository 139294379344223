export const API_URL = "https://esqyreapi.appgrowthcompany.com/"
export const GOOGLE_API_KEY = "AIzaSyAbsZUg1cv51-w4ejJh93F4w5DODotUWlk";



export const END_POINTS = {
    //corporate onboarding
    signUp: "onboarding/api/v1/User/signup",
    login: "onboarding/api/v1/User/login",
    verifyOtp: "onboarding/api/v1/User/verifyOtp",
    sendOtp: "onboarding/api/v1/User/sendOtp",
    changePassword: "onboarding/api/v1/User/changePassword",
    forgotPassword: "onboarding/api/v1/User/forgotPassword",
    resetPassword: "onboarding/api/v1/User/resetPassword",
    userDetails: "onboarding/api/v1/User/getProfile",
    updateProfile: "onboarding/api/v1/User/updateProfile",
    logout: "onboarding/api/v1/User/logout",
    upload: "common/api/v1/Upload/uploadFile",
    documents: "/onboarding/api/v1/User/documents",
    getReqDoc: "/onboarding/api/v1/User/requiredDocuments",

    //vendor onboarding
    signUpVendor: "onboarding/api/v1/User/signup",
    loginVendor: "onboarding/api/v1/User/login",
    verifyOtpVendor: "onboarding/api/v1/User/verifyOtp",
    sendOtpVendor: "onboarding/api/v1/User/sendOtp",
    changePasswordVendor: "onboarding/api/v1/User/changePassword",
    forgotPasswordVendor: "onboarding/api/v1/User/forgotPassword",
    resetPasswordVendor: "onboarding/api/v1/User/resetPassword",
    DetailsVendor: "onboarding/api/v1/User/getProfile",
    updateProfileVendor: "onboarding/api/v1/User/updateProfile",
    logoutVendor: "onboarding/api/v1/User/logout",

    //cms
    getCms: "common/api/v1/Content/getCms",
    faq: "/common/api/v1/Content/getFaq",






};