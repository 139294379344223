import { useEffect } from "react";
import LoginContent from "../../../layout/component/login-content";
import Document from "./featured/document";

const DocumentProof = () => {
  
  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, []);
  

  return (
    <>
      <section className="login-page">
        <div className="gap_m">
          <LoginContent />
          <Document />
        </div>
      </section>
    </>
  );
};

export default DocumentProof;
