/* eslint-disable import/no-anonymous-default-export */
import About from "./about";
import ContactUs from "./contact-us";
import Cookies from "./cookies";
import Faq from "./faq";
import FileSummarizationPage from "./fileSummarization";
import Home from "./home";
import PrivacyPolicy from "./privacy-policy";
import Service from "./service";
import Terms from "./terms";
export default {
  FileSummarizationPage,
  Home,
  About,
  Service,
  ContactUs,
  PrivacyPolicy,
  Faq,
  Cookies,
  Terms
};
