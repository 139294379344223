import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/store";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getFromStorage, removeFromStorage, setToStorage } from "../../constants/storage";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { showError } from "../../constants/toast";
import { usePostLoginMutation, useVendorPostLoginMutation } from "../../services/auth";
import Loader from "../../constants/Loader";
import { resetAuth, setCredentials } from "../../reducers/authSlice";

type props = {
    person: string;
}

const SignIn = ({ person }: props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showPassword, setShowPassword] = useState(false);
    const [loginApi] = usePostLoginMutation();
    const [loginApiVendor] = useVendorPostLoginMutation();
    const token = getFromStorage(STORAGE_KEYS.token)
    console.log(token, "token in profile");
    const location = useLocation();
    const { state } = location;



    // useEffect(() => {
    //     const data = getFromStorage(STORAGE_KEYS.credentials);
    //     if (data) {
    //         const rememberData = testJSON(data as string) && (data as string);
    //         formik.setFieldValue("password", rememberData?.password);
    //         formik.setFieldValue("email", rememberData?.email);
    //         formik.setFieldValue("remember", true);
    //     }
    // }, []);


    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            remember: false,
        },
        validationSchema: Yup.object({
            remember: Yup.boolean(),
            email: Yup.string()
                .required("Email is required")
                .matches(
                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    "Enter a valid email address!"
                ),
            password: Yup.string()
                .required("Password is required")
                .min(8, "Password should be min 8 letters"),
        }),
        onSubmit: async (values) => {
            formik.setSubmitting(true);

            let body = {
                email: formik.values.email,
                password: formik.values.password,
            };
            console.log(body, "body for login");
            if (person === "corporate") {
                try {
                    setIsLoading(true)
                    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
                    const response = await loginApi(encryptedBody).unwrap();
                    if (response?.statusCode === 200) {
                        setToStorage(STORAGE_KEYS.token, (response?.data?.accessToken) || null);
                        dispatch(
                            setCredentials({
                                user: response?.data || null,
                                token: response?.data?.accessToken || null,
                            })
                        );
                        response?.data?.isProfileCompleted ? (
                            window.open(`https://dev.appgrowthcompany.com/esqyre/corporateadmin/browser/dashboard?token=${response?.data?.accessToken}`)
                        ) : (
                            navigate("/profile-setup", { state: { email: formik.values.email, person: person } })
                        )
                        console.log("login ka res", response);
                    }
                    setIsLoading(false)
                } catch (error: any) {
                    setIsLoading(false)
                    showError(error?.data?.message);
                } finally {
                    setIsLoading(false)
                    formik.setSubmitting(false);
                }
            } else {
                try {
                    setIsLoading(true)
                    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
                    const response = await loginApiVendor(encryptedBody).unwrap();
                    if (response?.statusCode === 200) {
                        setToStorage(STORAGE_KEYS.token, (response?.data?.accessToken));
                        dispatch(
                            setCredentials({
                                user: response?.data || null,
                                token: response?.data?.accessToken || null,
                            })
                        );
                        response?.data?.isProfileCompleted ? (
                            window.open(`https://dev.appgrowthcompany.com/esqyre/vendorpanel/browser/dashboard?token=${response?.data?.accessToken}`)
                        ) : (
                            navigate("/profile-setup", { state: { email: formik.values.email, person: person } })
                        )

                        console.log("login ka res", response);
                    }
                    setIsLoading(false)
                } catch (error: any) {
                    setIsLoading(false)
                    showError(error?.data?.message);
                } finally {
                    setIsLoading(false)
                    formik.setSubmitting(false);
                }
            }

        },
    });

    useEffect(() => {
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.user)
        resetAuth();
    }, []);

    return (
        <>
            <Loader isLoad={isLoading} />
            <div className="login-box">
                <div className="login-heading">
                    <h2 className="heading-font">Welcome back</h2>
                    <p>Log in to Continue as {person === "vendor" ? "a Vendor" : "Corporate Admin"} </p>
                </div>
                <form className="form" onSubmit={formik.handleSubmit}>
                    <div className="control_group">
                        <TextField
                            hiddenLabel
                            placeholder="Email"
                            fullWidth
                            variant="outlined"
                            name="email"
                            type="email"
                            id="email"
                            onChange={(val) => {
                                if (val.target.value === " " || val.target.value === ".") {
                                } else {
                                    formik.handleChange(val);
                                }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            helperText={formik.touched.email && formik.errors.email}
                            error={
                                formik.touched.email && Boolean(formik.errors.email)
                            }
                        />
                    </div>
                    <div className="control_group">
                        <TextField
                            hiddenLabel
                            placeholder="Password"
                            fullWidth
                            name="password"
                            id="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            helperText={formik.touched.password && formik.errors.password}
                            type={showPassword ? "text" : "password"}
                            error={
                                formik.touched.password && Boolean(formik.errors.password)
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment className="eye_btn" position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(event) => event.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="form_btn text_center">
                        <Button className="btnn btn_primary big_cursor" type="submit">Log in</Button>
                    </div>
                </form >
                <div className="or-box">
                    <span>OR</span>
                </div>
                <div className="form_btn text_center">
                    <Button className="btnn btn_primary br big_cursor">
                        Login with UAE PASS
                    </Button>
                </div>
            </div >
        </>
    )
}

export default SignIn;