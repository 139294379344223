import Layout from "../../layout";
import Banner from "../../layout/component/banner";
import Contact from "../../layout/component/contact";
import Questions from "./features/questions";

const Faq = () => {
  const bannerData = {
    videoSrc: "./assets/images/faq.mp4",
    heading: "FAQ",
    subheading: "",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    showButton: false,
  };
  return (
    <Layout>
      <Banner
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
      />
      <Questions />
      <Contact />
    </Layout>
  );
};

export default Faq;
