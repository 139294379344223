import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";


type CommonResponseType = {
    statusCode: number;
    mesaage: string;
    message: string;
    token: string;
    user: User;
};



export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postSignUp: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.signUp,
                method: "POST",
                body,
            }),
        }),

        postLogin: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.login,
                method: "POST",
                body,
            }),
        }),

        postVerifyOtp: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.verifyOtp}`,
                method: "POST",
                body,
            }),
        }),

        postSendOtp: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.sendOtp}`,
                method: "POST",
                body,
            }),
        }),

        postProfileSetup: builder.mutation<
            CommonResponseType & { EMAIL_CHANGED: boolean } & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.updateProfile,
                method: "PUT",
                body,
            }),
        }),


        //documents Api's
        GetDocuments: builder.query<
            CommonResponseType & { data: any },
            {}
        >({
            query: () => ({
                url: END_POINTS.documents,
                method: "GET",
            }),
        }),

        GetReqDocuments: builder.query<
            CommonResponseType & { data: any },
            { docType: number }
        >({
            query: ({ docType }) => ({
                url: `${END_POINTS.getReqDoc}?docType=${docType}`,
                method: "GET",
            }),
        }),

        addDocumentsForsignup: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.documents,
                method: "POST",
                body
            }),
        }),


        //vendor onboarding api's

        vendorPostSignUp: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.signUpVendor,
                method: "POST",
                body,
            }),
        }),

        vendorPostLogin: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.loginVendor,
                method: "POST",
                body,
            }),
        }),

        vendorPostVerifyOtp: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.verifyOtpVendor}`,
                method: "POST",
                body,
            }),
        }),

        vendorPostSendOtp: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: `${END_POINTS.sendOtpVendor}`,
                method: "POST",
                body,
            }),
        }),

        vendorPostProfileSetup: builder.mutation<
            CommonResponseType & { EMAIL_CHANGED: boolean } & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.updateProfileVendor,
                method: "PUT",
                body,
            }),
        }),

    }),
});

export const {
    usePostSignUpMutation,
    usePostLoginMutation,
    usePostVerifyOtpMutation,
    usePostSendOtpMutation,
    usePostProfileSetupMutation,
    useLazyGetDocumentsQuery,
    useLazyGetReqDocumentsQuery,
    useAddDocumentsForsignupMutation,

    //vendor
    useVendorPostSignUpMutation,
    useVendorPostLoginMutation,
    useVendorPostVerifyOtpMutation,
    useVendorPostSendOtpMutation,
    useVendorPostProfileSetupMutation,
} = authApi;