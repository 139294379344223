import React from "react";
import Banner from "../../layout/component/banner";
import Layout from "../../layout";
import Contact from "../../layout/component/contact";
import Content from "./features/content";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {

  const location = useLocation();

  const bannerData = {
    videoSrc: "./assets/images/faq.mp4",
    heading: "Privacy Policy",
    subheading: "",
    description:
      "Privacy Policy",
    showButton: false,
  };
  return (
    <Layout>
      <Banner
        videoSrc={bannerData?.videoSrc}
        heading={bannerData?.heading}
        subheading={bannerData?.subheading}
        description={bannerData?.description}
        showButton={bannerData?.showButton}
      />
      <Content />
      <Contact />
    </Layout>
  );
};

export default PrivacyPolicy;
