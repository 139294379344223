import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLazyGetCmsQuery } from "../../services/cms";
import { showError } from "../../constants/toast";
import Loader from "../../constants/Loader";

const Footer = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getCmsData] = useLazyGetCmsQuery();
  const [cmsData, setCmsData] = useState<any>();
  const location = useLocation();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getCmsData({ role: 5 }).unwrap();
      if (res?.statusCode === 200) {
        setCmsData(res?.data[0]);
      }
      setIsLoading(false)
    } catch (error: any) {
      showError(error?.data?.message || "Something went wrong")
      setIsLoading(false);
    }
  }

  console.log(cmsData, "cmsData");


  useEffect(() => {
    fetchData();
  }, [])



  return (
    <footer>
      <Loader isLoad={isLoading} />
      <div className="conta_iner">
        <div className="logo_head">
          <figure className="logo-box big_cursor" onClick={() => navigate("/")}>
            <img src="./assets/images/logo.svg" alt="logo"></img>
          </figure>
        </div>
        <div className="ftr_flx">
          <div className="page_link big_cursor">
            <Link
              to="/about" state={cmsData}
              onClick={() => window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })}
            >
              About Us
            </Link>
            <Link
             onClick={() => window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })}
              to={{
                pathname: "/contact-us"
              }}
              state={cmsData}
            >Contact Us</Link>
            <p>Download the App</p>
            <div className="download-app">
              <Link to="">
                <img src="./assets/images/app_store.svg" alt="logo"></img>
              </Link>
              <Link to="">
                <img src="./assets/images/g_play.svg" alt="logo"></img>
              </Link>
            </div>
          </div>
          <div className="page_link big_cursor">
            <Link  onClick={() => window.scrollTo({
  top: 0,
  left: 0,
  behavior: 'smooth'
})} to="/faq">FAQ’s</Link>
            <Link
             onClick={() => window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })}
              to={{
                pathname: "/terms"
              }}
              state={cmsData}
            >Terms & conditions</Link>
            <Link
             onClick={() => window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })}
              to={{
                pathname: "/login"
              }}
              state={"vendor"}
            >Become a vendor</Link>

          </div>

          <div className="page_link big_cursor">
            <Link
             onClick={() => window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })}
              to={{
                pathname: "/privacy"
              }}
              state={cmsData}
            >Privacy Policy</Link>

            <Link
             onClick={() => window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })}
              to={{
                pathname: "/cookies"
              }}
              state={cmsData}
            >Cookies</Link>
          </div>
          <div className="subscribe-box">
            <label>SUBSCRIBE TO OUR NEWSLETTER</label>
            <div className="control_group">
              <TextField
                hiddenLabel
                placeholder="Enter your email"
                fullWidth
              />
              <button type="button" className="big_cursor">Subscribe</button>
            </div>
            <div className="scl_icn_mn">
              <h3>Follow us</h3>
              <ul className="scl_icn">
                <li className="big_cursor">
                  <figure><img src="./assets/images/facebook.png" alt="logo" /></figure>
                </li>
                <li className="big_cursor">
                  <figure><img src="./assets/images/instagram.png" alt="" /></figure>
                </li>
                <li className="big_cursor">
                  <figure><img src="./assets/images/twitter.png" alt="" /></figure>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer >
  );
};
export default Footer;
