import React from "react";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="contact-sec dark_bg">
        <div className="sb_hd text_center">
          <h2>Need any support? Let’s get in touch!</h2>
        </div>
        <div className="contact-circle big_cursor" onClick={() => navigate("/contact-us")}>
          <p>CONTACT US</p>
        </div>
      </section>
    </>
  );
};

export default Contact;
