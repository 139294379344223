import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

type props = {
  videoSrc: string;
  heading: string;
  subheading: string;
  description: any;
  showButton: boolean;
};

const Banner = ({
  videoSrc,
  heading,
  subheading,
  description,
  showButton,
}: props) => {
  useEffect(() => {
    AOS.init({});
  }, []);

  return (
    <>
      <section className="outter hero-video">
        <section className="video-container">
          <video src={videoSrc} autoPlay loop playsInline muted></video>
          <div className="callout">
            <h1 data-aos="fade-up" data-aos-duration="1000">
              {heading || ""}
              <br />
              {subheading || ""}
            </h1>
            <p data-aos="fade-up" data-aos-duration="2000"
              dangerouslySetInnerHTML=
              {{ __html: description ? description : "No information Provided" }} >

            </p>
            {showButton ? (
              <button
                className="btnn btn_white big_cursor"
                type="button"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <span>Explore</span>
              </button>
            ) : null}
          </div>
        </section>
      </section>
    </>
  );
};

export default Banner;
