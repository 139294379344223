import React from "react";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const navigate = useNavigate();
  return (
    <section className="bg_dark sprt_sc">
      <div className="conta_iner">
        <div className="d_flx">
          <div className="support-lhs sb_hd text_center">
            <h2 data-aos="fade-up" data-aos-duration="1000">
              Support
            </h2>
            <p data-aos="fade-up" data-aos-duration="2000">
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
            <button
              type="button"
              className="btnn btn_white big_cursor"
              data-aos="fade-up"
              data-aos-duration="3000"
              onClick={() => window.scrollBy({ top: 500, behavior: 'smooth' })}
            >
            Go To Support
          </button>
        </div>
        <div className="support-rhs sb_hd text_center">
          <h2 data-aos="fade-up" data-aos-duration="1000">
            Ask A Question
          </h2>
          <p data-aos="fade-up" data-aos-duration="2000">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
          <button
            className="btnn btn_white big_cursor"
            type="button"
            data-aos="fade-up"
            data-aos-duration="3000"
            onClick={() => navigate('/faq')}
          >
            <span>Go to Faq</span>
          </button>
        </div>
      </div>
    </div>
    </section >
  );
};

export default Support;
