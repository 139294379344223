import React, { useEffect } from "react";
import ProfileForm from "./featured/profile-form";
import LoginContent from "../../../layout/component/login-content";
import { useLocation } from "react-router-dom";
import VendorProfileSetup from "./featured/vendorProfileSetup";

const ProfileSetup = () => {

  const location = useLocation();
  const { state } = location;
  console.log(state, "state in index page");


  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "auto" });
    }
  }, []);

  return (
    <>
      <section className="login-page">
        <div className="gap_m">
          <LoginContent />
          {state?.person === "vendor" ? <VendorProfileSetup /> : <ProfileForm />}
        </div>
      </section>
    </>
  );
};

export default ProfileSetup;
