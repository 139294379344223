import React from "react";
import { useNavigate } from "react-router-dom";

const LoginContent = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="login_lhs w_50">
        <figure className="logo2_mg" onClick={() => navigate("/")}>
          <img src="./assets/images/logo2.svg" alt="logo"></img>
        </figure>
        <ul className="varient-box">
          <li>
            <figure>
              <img src="./assets/images/check.svg" alt="logo"></img>
            </figure>
            <div>
              <h2>Variety of Cars</h2>
              <p>
                It is a long established fact that a reader will be distracted by
                the readable content of a page
              </p>
            </div>
          </li>
          <li>
            <figure>
              <img src="./assets/images/check.svg" alt="logo"></img>
            </figure>
            <div>
              <h2>New Level Of Comfort</h2>
              <p>
                It is a long established fact that a reader will be distracted by
                the readable content of a page
              </p>
            </div>
          </li>
          <li>
            <figure>
              <img src="./assets/images/check.svg" alt="logo"></img>
            </figure>
            <div>
              <h2>24/7 support</h2>
              <p>
                It is a long established fact that a reader will be distracted by
                the readable content of a page
              </p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LoginContent;
