import React from "react";

const SendEmail = (props: any) => {
  return (
    <section className="call_sec">
      <div className="call_box sb_hd text_center text_white">
        <h2>SEND MAIL</h2>
        <a target="_blank"
          style={{
            fontSize: "18px",
            textDecoration: "underline",
            color: "white"
          }}
          href={"mailto:" + `${props?.data?.contactSupport?.email}`} rel="noreferrer" >
          {props?.data?.contactSupport?.email || "No email provided"}
        </a>
        <div className="address_box">
          <h4>IN THE UAE</h4>
          <h5>
            Esqyre Customer Assistance Center
            <br />
            P. O. Box 33169
            <br />
            Detroit, MI 48232-5169
          </h5>
        </div>
        <div className="address_box">
          <h4>International</h4>
          <h5>
            Esqyre Customer Assistance Center
            <br />
            P. O. Box 33169
            <br />
            Detroit, MI 48232-5169
          </h5>
        </div>
      </div>
      <p className="other_st">
        For the hearing and speech impaired, please call our Telecommunication
        Relay service at 711 for assistance.
      </p>
    </section>
  );
};

export default SendEmail;
